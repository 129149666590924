// ------------------ PANTALLAS ---------------------
// CELULARES
@mixin soloCelular {
    @media (max-width: 767px) {   
        @content; 
    }
}
@mixin desdeCelularGrande {
    @media (min-width: 576px) { 
        @content; 
    }
}
@mixin soloCelularGrande {
    @media (min-width: 576px) and (max-width: 768px) { 
        @content; 
    }
}
// TABLETS
@mixin soloTabletChica {
    @media (min-width: 768px) and (max-width: 992px) { 
        @content; 
    }
}
@mixin desdeTabletChica {
    @media (min-width: 768px) { 
        @content; 
    }
}
@mixin desdeTabletGrande {
    @media (min-width: 992px) { 
        @content; 
    }
}
@mixin soloTabletGrande {
    @media (min-width: 992px) and (max-width: 1200px) { 
        @content; 
    }
}
// LAPTOPS Y ESCRITORIO
@mixin desdeLaptop {
    @media (min-width: 1200px) { 
        @content; 
    }
}
@mixin soloLaptop {
    @media (min-width: 1200px) and (max-width: 1400px) { 
        @content; 
    }
}
@mixin desdeEscritorio {
    @media (min-width: 1400px) { 
        @content; 
    }
}

@mixin desdeEscritorioBig {
    @media (min-width: 1600px) { 
        @content; 
    }
}


@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

@mixin bgOptionsRepeat {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin select {
    appearance: none;
    background: none;
    outline: none;
    border: none;
}

@mixin nn(){
    background:none;
    outline: none;
    border:none;
}