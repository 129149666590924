@import 'vars.scss';

.capacidad-numeros-empresa {
    .capacidad-numeros-empresa-grid {
        .capacidad-numeros-empresa-singular {
            .titulo {
                @include desdeLaptop {
                    font-size: 2.1vw;
                }
            }
        }
    }
}