.login {
    min-height: 100vh;
    .formulario-login {
        width: 100%;
        @include desdeCelularGrande {
            width: 75%;
        }
        @include desdeTabletChica {
            width: 50%;
        }
        @include desdeTabletGrande {
            width: 25%;
        }
        input[type='email'], 
        input[type='password'] {
            display: block;
        }
        input[type='checkbox'] {
            position: relative;
            width: 13px;
            height: 13px;
            appearance: none;
            outline: none;
            border: 1px solid $gris-1;
            border-radius: 50%;
            cursor: pointer;
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 1px;
                width: 9px;
                height: 9px;
                // background-color: $morado-1;
                border-radius: 50%;
                transform: scale(0);
                transition: all .3s ease-in;
            }
            &:checked::before {
                transform: scale(1);
            }
        }
        .label-recuerdame {
            cursor: pointer;
        }
        .enlace-recuperar-password {
            color: $gris-1;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            } 
        }
        .texto-opcion {
            @include desdeLaptop {
                font-size: 1vw;
            }
        }
    }
}