@import "vars.scss";

.modal-boletin-informativo {
    .modal-dialog {
        max-width: 600px;
    }
}

.boletin-informativo {
    background-image: url(../img/bg-textura-azul.png);
    background-size: contain;
    background-position: center;
    background-repeat: repeat;
    .titulo {
        @include desdeLaptop {
            font-size: 1.8vw;
        }
    }
}