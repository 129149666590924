@import 'vars.scss';

.tarjetas-servicios {
    margin-top: -20px;
    z-index: 1;
    @include desdeLaptop {
        margin-top: -50px;
    }
    .columna-tarjeta {
        // @include desdeTabletChica {
        //     margin-top: -50px;
        // } 
        z-index: 1;
    }
    .tarjeta-singular {
        .titulo {
            @extend .roboto-slab-normal;
            min-height: 90px;
            @include desdeLaptop {
                font-size: 1.3vw;
            }
        }
    }
}

.video-cobranza-internacional{
    video{
        width: 100%;
        height: 47vw;  
    }
}
