@import "vars.scss";
@import "fonts.scss";
@import "mixin.scss";
@import "keyframes.scss";

// ---------------ESTILOS GENERALES--------------- 
body {
    // font-size: 16px;
    font-size: 1.2vw;
    @extend .avenir-normal;
    @extend .color-negro;
}
h1 {
    @extend .roboto-slab-medium;
    font-size: 3.1vw;
    &.titulo-mediano {
        @extend .roboto-slab-xbold; 
        font-size: 3.7vw;
    }
    &.titulo-grande {
        @extend .roboto-slab-xbold; 
        font-size: 6.2vw;
    }
}
h2 {
    @extend .roboto-slab-medium;
    font-size: 3.1vw;
}
h3 {
    @extend .roboto-slab-medium;
    font-size: 2.5vw;
}
h4 {
    @extend .roboto-slab-medium;
    font-size: 1.8vw;  
}
h5 {
    @extend .avenir-black;
    font-size: 1vw;
}
h6 {
    @extend .avenir-black;
    font-size: 1vw;
}
p {
    font-size: 1.2vw;
    line-height: 1.2;
}

a {
    text-decoration: none;
}

ul, li {
    list-style: none;
}

form{
    label, textarea, input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="number"], input[type="date"], select{
        width: 100%;
        font-size: 1.2vw;
    }
}
input {
    outline: none;
}
textarea {
    outline: none;
    min-height: 250px;
    max-height: 250px;  
}

// ---------------CLASES GENERALES---------------
// LETRA
.avenir-normal {
    font-family: 'Avenir LT Std';
    font-weight: normal;
}
.avenir-black {
    font-family: 'Avenir LT Std';
    font-weight: 900;
}
.roboto-slab-normal {
    font-family: 'Roboto Slab';
    font-weight: normal;
}
.roboto-slab-medium {
    font-family: 'Roboto Slab';
    font-weight: 500;
}
.roboto-slab-xbold {
    font-family: 'Roboto Slab';
    font-weight: bold;
}
.akira {
    font-family: 'Akira Expanded';
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-cerrar {
    &:hover {
        @extend .color-naranja-2;
    }
}

.btn {  
    @extend .radius-50;
    &.avenir-chico {
        @extend .avenir-normal;
        font-size: 1vw;
    }
    &.avenir-mediano {
        @extend .avenir-normal;
        font-size: 1.8vw;
    }
    &.roboto-slab-mediano {
        @extend .roboto-slab-medium;
        font-size: 2.5vw;
    }
    &.roboto-slab-grande {
        @extend .roboto-slab-xbold;
        font-size: 3.1vw;
    }
    &:focus {
        box-shadow: none;
    }
    @include toAnim(.3s);
    &:hover{
        opacity: .8;
    }
}

.titulo-seccion {
    display: inline-block;
    .linea-abajo {
        width: 100%;
        height: 5px;
        @extend .radius-50;
    }
}

//COLORES
.color-blanco {
    color: $blanco;
}  
.color-negro {
    color: $negro;
}

.color-azul-1 {
    color: $azul-1;
}
.color-azul-2 {
    color: $azul-2;
}
.color-azul-3 {
    color: $azul-3;
}
.color-azul-4 {
    color: $azul-4;
}

.color-gris-1 {
    color: $gris-1;
}
.color-gris-2 {
    color: $gris-2;
}
.color-gris-3 {
    color: $gris-3;
}
.color-gris-4 {
    color: $gris-4;
}

.color-naranja-1 {
    color: $naranja-1;
}
.color-naranja-2 {
    color: $naranja-2;
}

.color-verde-1 {
    color: $verde-1;
}

//BACKGROUNDS-COLOR 
.bg-blanco {
    background-color: $blanco;
}
.bg-negro {
    background-color: $negro;
}

.bg-azul-1 {
    background-color: $azul-1;
}
.bg-azul-2 {
    background-color: $azul-2;
}
.bg-azul-3 {
    background-color: $azul-3;
}
.bg-azul-4 {
    background-color: $azul-4;
}

.bg-gris-1 {
    background-color: $gris-1;
}
.bg-gris-2 {
    background-color: $gris-2;
}
.bg-gris-3 {
    background-color: $gris-3;
}
.bg-gris-4 {
    background-color: $gris-4;
}

.bg-naranja-1 {
    background-color: $naranja-1;
}
.bg-naranja-2 {
    background-color: $naranja-2;
}

.bg-verde-1 {
    background-color: $verde-1;
}

//COLORES DE BORDE
.border-blanco {
    border-color: $blanco !important;
}
.border-negro {
    border-color: $negro !important;
}

.border-azul-1 {
    border-color: $azul-1 !important;
}
.border-azul-2 {
    border-color: $azul-2 !important;
}
.border-azul-3 {
    border-color: $azul-3 !important;
}
.border-azul-4 {
    border-color: $azul-4 !important;
}

.border-gris-1 {
    border-color: $gris-1 !important;
}
.border-gris-2 {
    border-color: $gris-2 !important;
}
.border-gris-3 {
    border-color: $gris-3 !important;
}
.border-gris-4 {
    border-color: $gris-4 !important;
}

.border-naranja-1 {
    border-color: $naranja-1 !important;
}
.border-naranja-2 {
    border-color: $naranja-2 !important;
}

.border-verde-1 {
    border-color: $verde-1 !important;
}

//DEGRADADOS Y OPACIDADES PARA LOS BANNERS PRINCIPALES
.degradado-principal {
    @extend .bg-gris-1;
    background: linear-gradient(180deg, rgba($gris-1, 1) 0%, rgba($gris-1, .95) 23%, rgba($negro, .8) 100%);
    opacity: .78;
}
.degradado-secundario {
    background-color: $negro;
    opacity: .5;
}
.degradado-azul {
    background-image: linear-gradient(90deg, rgba($blanco, .4), rgba($azul-3, 1) 80%);
    opacity: .5;
}

.radius-20 {
    border-radius: $radius-20;
}
.radius-50 {
    border-radius: $radius-50;
}

.container {
    max-width: 88%;
}

.only-desk {
    display: block;
}

.only-mobile {
    display: none;
}

.bg-formulario-contacto {
    height: 100%;
    @include bgOptionsRepeat;
}

.numero-grande-1 {
    @extend .avenir-black;
    font-size: 5vw;
    @include desdeLaptop {
        font-size: 5vw;
    }
}

.nav-link {
    font-size: 1vw;
}

.rotar-circulo {
    animation: rotarCirculo 6s ease 0s 1 normal forwards;
}

.btn-alguna-duda {
    position: fixed;
    bottom: 10%;
    right: 40px;
    z-index: 2;
    .icono {
        width: 32px;
    }
}

@import "header.scss";
@import "home.scss";
@import "nuestro-enfoque.scss";
@import "servicios.scss";
@import "contacto.scss";
@import "nosotros.scss";
@import "boletin-informativo.scss";
@import "blog.scss";
@import "login.scss";
@import "registro.scss";
@import "como-funciona.scss";
@import "valores.scss";
@import "repeat.scss";
@import "footer.scss";
@import "breakpoint.scss";

