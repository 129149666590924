.registro {
    min-height: 100vh;
    .formulario-registro {
        width: 75%;
        @include desdeLaptop {
            width: 50%;
        }
        .texto-opcion {
            @include desdeLaptop {
                font-size: 1vw;
            }
        }
    }
}