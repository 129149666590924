@import 'vars.scss';

.reglas-gestion-cartera-comercial {
    .reglas-gestion-cartera-comercial-grid {
        .regla-gestion-cartera-comercial-singular {
            .numero {
                font-size: 6rem;
                opacity: .8;
                transition: all .3s ease;
                @include desdeLaptop {
                    font-size: 10.4vw;
                }
            }
            &:hover .numero {
                color: $azul-3;
            }
        }
    }
}