@font-face {
    font-family: 'Avenir LT Std';
    src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir LT Std';
    src: url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
        url('../fonts/RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/RobotoSlab-Medium.woff2') format('woff2'),
        url('../fonts/RobotoSlab-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/RobotoSlab-ExtraBold.woff2') format('woff2'),
        url('../fonts/RobotoSlab-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Akira Expanded';
    src: url('../fonts/AkiraExpanded-SuperBold.woff2') format('woff2'),
        url('../fonts/AkiraExpanded-SuperBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}





