@import "vars.scss";
@import "fonts.scss";
@import "mixin.scss";
@import "keyframes.scss";


.flip-slide{
    h3{
        font-size: 3vw;
    }
}


/*! jQuery.Flipster, v1.1.5 (built 2020-10-17) */
/* @group Flipster Essential Styles */
.flipster {
    display: block;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
  .flipster:focus {
    outline: none;
  }
  .flipster__container,
  .flipster__item {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
  }
  .flipster__container {
    display: block;
    height: auto !important;
    white-space: nowrap;
    word-spacing: -0.25em;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .flipster__item {
    display: inline-block;
    white-space: normal;
    word-spacing: normal;
    vertical-align: bottom;
  }
  .flipster--click .flipster__item--past,
  .flipster--click .flipster__item--future {
    cursor: pointer;
  }
  .flipster__item img {
    max-width: 100%;
  }
  /* @end */
  
  /* @group Flipster Previous & Next Buttons */
  .flipster__button {
    position: absolute;
    top: 50%;
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: none;
    border: none;
    padding: 0;
    z-index: 999;
    cursor: pointer;
    font-size: 15px;
    opacity: 0.5;
    -webkit-transition: opacity 500ms ease;
    -o-transition: opacity 500ms ease;
    transition: opacity 500ms ease;
    margin: -1em 2em;
  }
  .flipster__button svg {
    width: 2em;
    stroke: currentColor;
    fill: transparent;
    stroke-width: 3;
    stroke-linecap: round;
  }
  .flipster__button:hover,
  .flipster__button:focus {
    opacity: 1;
  }
  .flipster__button--prev {
    left: 0;
  }
  .flipster__button--next {
    right: 0;
  }
  /* @end */
  
  /* @group Flipster Navigation */
  .flipster__nav,
  .flipster__nav__item {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .flipster__nav {
    display: block;
    margin: 0 0 4em;
    text-align: center;
    position: relative;
  }
  .flipster__nav__item {
    display: inline-block;
    margin: 0 0.25em;
  }
  .flipster__nav__link {
    display: block;
    color: inherit;
    padding: 0.5em 1em;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
    -webkit-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
         -o-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
            transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  }
  .flipster__nav__link::after {
    content: '';
    display: block;
    background: #232221;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transform: translateY(100%) translateY(-0.25em);
        -ms-transform: translateY(100%) translateY(-0.25em);
            transform: translateY(100%) translateY(-0.25em);
    -webkit-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
  }
  .flipster__nav__item--current > .flipster__nav__link,
  .flipster__nav__link:hover,
  .flipster__nav__link:focus {
    color: #FFF;
  }
  .flipster__nav__item--current > .flipster__nav__link::after,
  .flipster__nav__link:hover::after,
  .flipster__nav__link:focus::after {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
  }
  .flipster__nav__child {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -1px;
    padding: 0.5em;
    background: #4e4441;
    z-index: 1;
  }
  .flipster__nav__child .flipster__nav__link {
    color: #FFF;
  }
  .flipster__nav__child .flipster__nav__link::after {
    background: #FFF;
  }
  .flipster__nav__child .flipster__nav__item--current > .flipster__nav__link,
  .flipster__nav__child .flipster__nav__link:hover,
  .flipster__nav__child .flipster__nav__link:focus {
    color: #232221;
  }
  .flipster__nav__item--current .flipster__nav__child {
    display: block;
  }
  /* @end */
  
  /* @group Flipster Carousel Theme */
  .flipster--carousel .flipster__container,
  .flipster--carousel .flipster__item,
  .flipster--carousel .flipster__item__content {
    -webkit-transition: all 350ms ease-in-out;
    -o-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;
    -webkit-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
         -o-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
            transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  }
  .flipster--carousel .flipster__item {
    -webkit-perspective: 800px;
            perspective: 800px;
            
  }
  .flipster--carousel .flipster__item--past,
  .flipster--carousel .flipster__item--future {
    opacity: 0;
    -webkit-transition-delay: 115ms;
         -o-transition-delay: 115ms;
            transition-delay: 115ms;
  }
  .flipster--carousel .flipster__item--past-2,
  .flipster--carousel .flipster__item--future-2 {
    opacity: 0.6;
    -webkit-transition-delay: 90ms;
         -o-transition-delay: 90ms;
            transition-delay: 90ms;
  }
  .flipster--carousel .flipster__item--past-1,
  .flipster--carousel .flipster__item--future-1 {
    opacity: 0.8;
    -webkit-transition-delay: 60ms;
         -o-transition-delay: 60ms;
            transition-delay: 60ms;
  }
  .flipster--carousel .flipster__item--past .flipster__item__content {
    -webkit-transform: translateX(100%) rotateY(-20deg) scale(0.5);
            transform: translateX(100%) rotateY(-20deg) scale(0.5);
  }
  .flipster--carousel .flipster__item--past-2 .flipster__item__content {
    -webkit-transform: translateX(25%) rotateY(40deg) scale(0.65);
            transform: translateX(25%) rotateY(40deg) scale(0.65);
  }
  .flipster--carousel .flipster__item--past-1 .flipster__item__content {
    -webkit-transform: rotateY(45deg) scale(0.8);
            transform: rotateY(45deg) scale(0.8);
  }
  .flipster--carousel .flipster__item--future .flipster__item__content {
    -webkit-transform: translateX(-100%) rotateY(20deg) scale(0.5);
            transform: translateX(-100%) rotateY(20deg) scale(0.5);
  }
  .flipster--carousel .flipster__item--future-2 .flipster__item__content {
    -webkit-transform: translateX(-25%) rotateY(-40deg) scale(0.65);
            transform: translateX(-25%) rotateY(-40deg) scale(0.65);
  }
  .flipster--carousel .flipster__item--future-1 .flipster__item__content {
    -webkit-transform: rotateY(-45deg) scale(0.8);
            transform: rotateY(-45deg) scale(0.8);
  }
  .flipster--carousel.no-rotate .flipster__item--past .flipster__item__content {
    -webkit-transform: translateX(175%) scale(0.5);
        -ms-transform: translateX(175%) scale(0.5);
            transform: translateX(175%) scale(0.5);
  }
  .flipster--carousel.no-rotate .flipster__item--past-2 .flipster__item__content {
    -webkit-transform: translateX(25%) scale(0.65);
        -ms-transform: translateX(25%) scale(0.65);
            transform: translateX(25%) scale(0.65);
  }
  .flipster--carousel.no-rotate .flipster__item--past-1 .flipster__item__content {
    -webkit-transform: translateX(0%) scale(0.8);
        -ms-transform: translateX(0%) scale(0.8);
            transform: translateX(0%) scale(0.8);
  }
  .flipster--carousel.no-rotate .flipster__item--future .flipster__item__content {
    -webkit-transform: translateX(-175%) scale(0.5);
        -ms-transform: translateX(-175%) scale(0.5);
            transform: translateX(-175%) scale(0.5);
  }
  .flipster--carousel.no-rotate .flipster__item--future-2 .flipster__item__content {
    -webkit-transform: translateX(-25%) scale(0.65);
        -ms-transform: translateX(-25%) scale(0.65);
            transform: translateX(-25%) scale(0.65);
  }
  .flipster--carousel.no-rotate .flipster__item--future-1 .flipster__item__content {
    -webkit-transform: translateX(0%) scale(0.8);
        -ms-transform: translateX(0%) scale(0.8);
            transform: translateX(0%) scale(0.8);
  }
  .flipster--carousel .flipster__item--current .flipster__item__content {
    -webkit-transform: translateX(0) rotateY(0deg) scale(1);
            transform: translateX(0) rotateY(0deg) scale(1);
    -webkit-transition-delay: 60ms;
         -o-transition-delay: 60ms;
            transition-delay: 60ms;
  }
  /* @end */
  
  /* @group Flipster Coverflow Theme */
  .flipster--coverflow .flipster__container,
  .flipster--coverflow .flipster__item,
  .flipster--coverflow .flipster__item__content {
    -webkit-transition: all 350ms ease-in-out;
    -o-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;
    -webkit-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
         -o-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
            transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  }
  .flipster--coverflow .flipster__item {
    -webkit-perspective: 800px;
            perspective: 800px;
  }
  .flipster--coverflow .flipster__container {
    padding-bottom: 5%;
  }
  .flipster--coverflow .flipster__item__content {
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    box-reflect: below 0 -webkit-gradient(linear, left bottom, left top, color-stop(0.05, rgba(255, 255, 255, 0.12)), color-stop(0.2, transparent));
    -webkit-box-reflect: below 0 -webkit-gradient(linear, left bottom, left top, color-stop(0.05, rgba(255, 255, 255, 0.12)), color-stop(0.2, transparent));
  }
  .flipster--coverflow .flipster__item__content img:only-child {
    display: block;
  }
  .flipster--coverflow .flipster__item--past .flipster__item__content {
    -webkit-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(0.75) rotateY(55deg);
            transform: scale(0.75) rotateY(55deg);
  }
  .flipster--coverflow .flipster__item--future .flipster__item__content {
    -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(0.75) rotateY(-55deg);
            transform: scale(0.75) rotateY(-55deg);
  }
  .flipster--coverflow .flip-current .flipster__item__content {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  /* @end */
  
  /* @group Flat */
  .flipster--flat .flipster__container,
  .flipster--flat .flipster__item,
  .flipster--flat .flipster__item__content {
    -webkit-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
  }
  .flipster--flat .flipster__item--past,
  .flipster--flat .flipster__item--future {
    opacity: 0.5;
  }
  .flipster--flat .flipster__item--past .flipster__item__content {
    -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
            transform: scale(0.75);
  }
  .flipster--flat .flipster__item--future .flipster__item__content {
    -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
            transform: scale(0.75);
  }
  /* @end */
  
  /* @group Flipster Wheel Theme */
  .flipster--wheel {
    overflow: hidden;
  }
  .flipster--wheel .flipster__container,
  .flipster--wheel .flipster__item__content {
    -webkit-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    -webkit-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
         -o-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
            transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  }
  .flipster--wheel .flipster__container {
    padding-bottom: 20%;
  }
  .flipster--wheel .flipster__item__content {
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  .flipster--wheel .flipster__item--past .flipster__item__content {
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .flipster--wheel .flipster__item--future .flipster__item__content {
    -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  .flipster--wheel .flipster__item__content img:only-child {
    display: block;
  }
  .flipster--wheel .flipster__item--past .flipster__item__content {
    opacity: 0;
    -webkit-transform: rotateZ(-80deg) translate(-170%, 110%);
        -ms-transform: rotate(-80deg) translate(-170%, 110%);
            transform: rotateZ(-80deg) translate(-170%, 110%);
  }
  .flipster--wheel .flipster__item--future .flipster__item__content {
    opacity: 0;
    -webkit-transform: rotateZ(80deg) translate(170%, 110%);
        -ms-transform: rotate(80deg) translate(170%, 110%);
            transform: rotateZ(80deg) translate(170%, 110%);
  }
  .flipster--wheel .flipster__item--past-3 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(-60deg) translate(-70%, 75%);
        -ms-transform: rotate(-60deg) translate(-70%, 75%);
            transform: rotateZ(-60deg) translate(-70%, 75%);
  }
  .flipster--wheel .flipster__item--future-3 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(70%, 75%);
        -ms-transform: rotate(60deg) translate(70%, 75%);
            transform: rotateZ(60deg) translate(70%, 75%);
  }
  .flipster--wheel .flipster__item--past-2 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(-40deg) translate(-17%, 30%);
        -ms-transform: rotate(-40deg) translate(-17%, 30%);
            transform: rotateZ(-40deg) translate(-17%, 30%);
  }
  .flipster--wheel .flipster__item--future-2 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(40deg) translate(17%, 30%);
        -ms-transform: rotate(40deg) translate(17%, 30%);
            transform: rotateZ(40deg) translate(17%, 30%);
  }
  .flipster--wheel .flipster__item--past-1 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(-20deg);
        -ms-transform: rotate(-20deg);
            transform: rotateZ(-20deg);
  }
  .flipster--wheel .flipster__item--future-1 .flipster__item__content {
    opacity: 1;
    -webkit-transform: rotateZ(20deg);
        -ms-transform: rotate(20deg);
            transform: rotateZ(20deg);
  }
  .flipster--wheel .flip-current .flipster__item__content {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  /* @end */
  