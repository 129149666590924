@import "vars.scss";

.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%; 
    z-index: 2;
    box-shadow: 0 0 20px 0 rgba($negro, .1);
    &.header-home {
        position: fixed;
        background-color: transparent;
        box-shadow: none;
        transition: all .6s ease;
        &.active {
            @extend .bg-blanco;
            box-shadow: 0 0 20px 0 rgba($negro, .1);
        }
    }
    .logo {
        width: 20vw;
        @include desdeTabletChica {
            width: 12vw;
        }
    }
}

.header {
    .nav-link {
        &:hover {
            @extend .color-azul-3;
        }
    }
}

.navbar{
    ul{
        padding: 0;
        margin: 0 auto;
        li{
            position: relative;
            ul{
                position: absolute;
                top: 110%;
                left: 0%;
                /* transform: translateX(-50%); */
                background-color: $gris-1;
                visibility: hidden;
                opacity: 0;
                @include toAnim(0.3s);
                li{
                    a{
                        padding: 0.5vw;
                        display: inline-block;
                        text-decoration: none;
                        color: black;
                        white-space: nowrap;
                    }
                }
            }
            &:hover{
                ul{
                    top: 100%;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

.banner-principal {
    position: relative;
    min-height: 100vh;
    @include bgOptionsRepeat;
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend .degradado-principal;
    }
    .contenido-banner {
        z-index: 1;
    }
}
.banner-secundario {
    position: relative;
    min-height: 40vw;
    @include bgOptionsRepeat;
    &.video {
        .bg-video {
            vertical-align: top;
        }
        .contenido-banner {
            position: absolute;
            top: calc(50% - 90px);
        }
        .contenido-boton {
            position: absolute;
            top: calc(80% - 90px);
            z-index: 1;
        }
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // z-index: 10;
        @extend .degradado-secundario;
    }
    .contenido-banner {
        z-index: 1;
    }
}

.slide-menu-mobile {
    position: fixed;
    top: 0;
    left: -900px;
    width: 80%;
    height: 100%;
    box-shadow: 0 0 20px 0 rgba($negro, .2);
    z-index: 3;
    overflow-y: scroll;
    transition: all .6s ease-in;
    &.show-slide-menu-mobile {
        left: 0;
    }
    @include desdeCelularGrande {
        width: 70%;
    }
    @include desdeTabletChica {
        width: 60%;
    }
    @include desdeTabletGrande {
        width: 40%;
    }
    a {
        &.active {
            color: $naranja-1;
        }
        &:hover {
            color: $naranja-1;
        }
    }
    .accordion-item {
        border: 0 !important;
    }
    .accordion-button {
        outline: none !important;
        box-shadow: none !important;
    }
}
.overlay-slide-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $negro;
    opacity: .2;
    z-index: 2;
    visibility: hidden;
    &.show-overlay-slide-menu-mobile {
        visibility: visible;
    }
}

