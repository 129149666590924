@import 'vars.scss';


// PÁGINA TESTIMONIOS
.testimonios {
    .lista-testimonios-grid {
        .testimonio-singular {
            .testimonio-singular-imagen {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                clip-path: polygon(50% 0, 97% 25%, 97% 75%, 50% 100%, 3% 75%, 3% 25%);
                width: 88%;
                height: 88%;
                img {
                    object-fit: cover;
                    width: 100%; 
                    height: 100%;
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $azul-3;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: .6;
                }
            }
            .testimonio-singular-contenido {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .nombre {
                    @include desdeLaptop {
                        font-size: 2.1vw;
                    }
                }
                .texto-cita-testimonio {
                    @include desdeLaptop {
                        font-size: 1vw;
                    }
                }
            }
        }
        .swiper-slide {
            &:nth-child(2n + 1) {
                .testimonio-singular-imagen {
                    &::after {
                        background-color: $naranja-2;
                    }
                }
            } 
        }
    }
}


//nosotros


.linea-tiempo{
    .item{
        width: 19%;
        background-color: rgba(white, 0.8);
        @include toAnim(0.5s);
        opacity: 0;
        transform: translateY(-5%);
        h4{
            font-size: 3vw;
        }
        p{
            font-size: 1.2vw;
        }
        &.view{
            opacity: 1;
            transform: translateY(0%);
        }
    }
}