@-webkit-keyframes pio {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @-moz-keyframes pio {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @-o-keyframes pio {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes pio {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes rotarCirculo {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}