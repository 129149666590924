@import 'vars.scss';

.modal-home {
    .modal-home-bg {
        @include bgOptionsRepeat;
        height: 90vh;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @extend .degradado-azul;
        }
        .contenido-modal {
            z-index: 1;
        }
    }
    .btn-close {
        fill: #fff;
    }
}

.acerca-de-servicios-soluciones {
    .servicios-soluciones {
        background-image: url(../img/bg-textura-blanco.png);
        // min-height: 40vw;
        @include bgOptionsRepeat;
        .lista-servicios-soluciones-grid {
            margin-top: 90px;
            .servicio-solucion-singular {
                &:hover {
                    .icono-no-color {
                        filter: grayscale(0);
                    }            
                }
                .icono {
                    width: 128px;
                }
                .icono-no-color {
                    filter: grayscale(1);
                    transition: all .6s ease;
                }
                .titulo {
                    @include desdeLaptop {
                        font-size: 2.1vw;
                    }
                }
            }
        }
    }
    .servicios-soluciones-more {
        // background-image: linear-gradient(90deg, rgba($negro, .5) 0%, rgba($negro, .5) 100%), url(../img/servicios-soluciones-bg.jpg);
        @include bgOptionsRepeat;
        .servicio-singular {
            min-height: 40vw;
            .titulo {
                @include desdeLaptop {
                    font-size: 3.7vw;
                }
            }
            .contenido {
                @include desdeTabletChica {
                    width: 50%;
                }
            }
        }
    }
}

.enfoque {
    min-height: 40vw;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::after {
        content: "360°";
        color: $blanco;
        opacity: .2;
        letter-spacing: 20px;
        @extend .akira;
        font-size: 6rem;
        @include desdeTabletChica {
            font-size: 28vw;
        }
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        text-align: center;
        width: 100%;
        z-index: -1;
    }
    .item-enfoque-singular {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .contenido {
            position: absolute;
            width: 80%;
            @include desdeTabletChica {
                width: 65%;
            }
            .icono-item {
                width: 64px;
            }
        }
        .texto {
            @include desdeLaptop {
                font-size: 1vw;
            }
        }
    }
}



.clientes {
    .logo {
        width: 120px;
    }
}

.cta-pagos-seguros-en-linea {
    .cta-pagos-seguros-en-linea-grid {
        .titulo {
            @include desdeLaptop {
                font-size: 3.7vw;
            }
        }
        .logo-pse {
            width: 190px;
        }
    }
}


