
// X-Small devices (portrait phones, less than 576px)
@include soloCelular {
    // ---------------ESTILOS GENERALES--------------- 
    body {
        width: 100%;
        height: 100%;
        font-size: 16px;
    }
    h1 {
        font-size: 2.2rem;
        &.titulo-mediano {
            font-size: 2.4rem;
        }
        &.titulo-grande {
            font-size: 3rem;
        }
    }
    h2 {
        font-size: 1.8em;
    }
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.3rem;
    }
    h5 {
        font-size: 1.2rem;
    }
    h6 {
        font-size: 1.2rem;
    }
    p {
        font-size: 1rem;
    }

    form{
        label, textarea, input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="number"], input[type="date"], select{
            font-size: 1rem;
        }
    }

    // ---------------CLASES GENERALES--------------- 
    .btn {  
        &.avenir-chico {
            font-size: .6rem;
        }
        &.avenir-mediano {
            font-size: .8rem;
        }
        &.roboto-slab-mediano {
            font-size: 1rem;
        }
        &.roboto-slab-grande {
            font-size: 1.2rem;
        }
    }

    .nav-link {
        font-size: 1rem;
    }
}

// Small devices (landscape phones, less than 768px)
@include soloCelular { 
    .only-mobile {
        display: block;
    }
    .only-desk {
        display: none;
    }
}

// Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) { 
    
// }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) { 
    
// }

// // X-Large devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px) { 

// }
