@import "vars.scss";
@import "fonts.scss";
@import "mixin.scss";
@import "keyframes.scss";



h2.ss-subtitle{
    padding: 10px 10px 40px;
    font-size: 52px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.8);
	position: relative;
	text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}
h2.ss-subtitle:before{
	width: 4px;
	height: 40px;
	background: rgba(17,17,22,0.8);
	content: '';
	position: absolute;
	right: 75%;
	margin-right: -4px;
	bottom: -4px;
	-moz-border-radius: 2px 2px 0px 0px;
	-webkit-border-radius: 2px 2px 0px 0px;
	border-radius: 2px 2px 0px 0px;
}
h2.ss-subtitle:after{
	width: 25%;
	height: 0px;
	border-bottom: 4px dotted rgba(17,17,22,0.8);
	content: '';
	position: absolute;
	right: 50%;
	margin-right: -1px;
	bottom: -4px;
}
.ss-links{
	position: fixed;
	left: 10px;
	top: 166px;
	width: 25%;
	z-index: 100;
}
.ss-links a{
	background: rgba(0,0,0,0.2);
	font-size: 16px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin: 5px;
	float: left;
	border-radius: 50%;
	display: block;
	text-align: center;
	-webkit-transition: background 0.2s linear;
	-moz-transition: background 0.2s linear;
	-o-transition: background 0.2s linear;
	-ms-transition: background 0.2s linear;
	transition: background 0.2s linear;
}
.ss-links a:hover{
	background: rgba(0,0,0,0.4);
}
.ss-container{
    width: 100%;
    position: relative;
    text-align: left;
    float: left;
	overflow: hidden;
	/* padding-bottom: 500px; */
    padding-bottom: 10vw;
}
.ss-container h2{
    font-size: 40px;
    text-transform: uppercase;
    color: rgba(78,84,123,0.2);
	text-shadow: 0px 1px 1px #fff;
	padding: 20px 0px;
}
.ss-container:before{
    position: absolute;
    width: 2vw;
    background-color: #4aadff;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
.ss-row{
    width: 100%;
    clear: both;
    float: left;
    position: relative;
    /* padding: 30px 0; */
    padding: 1.5vw 0;
}
.ss-left, .ss-right{
    float: left;
    width: 50%;
    position: relative;
    min-height: 10vw;
    h4{
        font-size: 2.2vw;
        max-width: 20%;
    }
    p{
        max-width: 60%;
    }
}
.ss-right{
    padding-left: 2%;
}
.ss-left{
    text-align: right;
    float: left;
    padding-right: 2%;
}
.ss-circle{
    border-radius: 50%;
	position: relative;
    .marco{
        width: 10vw;
	    height: 10vw;
        position: relative;
        z-index: 1;
    }
    .foto{
        width: 10vw;
	    height: 10vw;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
    }
}



.ss-left .ss-circle-deco:before{
    right: 2%;   
}
.ss-right .ss-circle-deco:before{
    left: 2%;   
}
.ss-left .ss-circle-deco:after{
	right: 0;
	border-right: 10px solid rgba(17,17,22,0.8);
}
.ss-right .ss-circle-deco:after{
	left: 0;
	border-left: 10px solid rgba(17,17,22,0.8);
}
.ss-left .ss-circle{
    float: right;
    /* margin-right: 30%; */
}
.ss-right .ss-circle{
    float: left;
    /* margin-left: 30%; */
}
.ss-container h3{
    margin-top: 34px;
	padding: 10px 15px;
	background: rgba(26, 27, 33, 0.6);
	text-shadow: 1px 1px 1px rgba(26, 27, 33, 0.8)
}
.ss-container .ss-medium h3{
	margin-top: 82px;
}
.ss-container .ss-large h3{
	margin-top: 133px;
}
.ss-container .ss-left h3{
	border-right: 5px solid rgba(164,166,181,0.8);
}
.ss-container .ss-right h3{
	border-left: 5px solid rgba(164,166,181,0.8);
}
.ss-container h3 span{
    color: rgba(255,255,255,0.8);
    font-size: 13px;
    display: block;
    padding-bottom: 5px;
}
.ss-container h3 a{
    font-size: 28px;
    color: rgba(255,255,255,0.9);
    display: block;
}
.ss-container h3 a:hover{
	color: rgba(255,255,255,1);
}