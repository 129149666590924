@import "vars.scss";

.footer {
    .nav-link {
        &:hover {
            @extend .color-naranja-2;
        }
    }
    .logo {
        width: 30vw;
        @include desdeTabletChica {
            width: 20vw;
        }
    }
    .redes-sociales {
        .icono {
            width: 32px;
        }
    }
    .contacto {
        .icono {
            width: 16px;
        }
    }
    .logo-iridian {
        width: 64px;
    }
}