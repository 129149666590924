// COLORES
$blanco: #FFF;
$negro: #000;

$azul-1: #4AADFF;
$azul-2: #00A2AD;
$azul-3: #00669B;
$azul-4: #014266;

$gris-1: #E5E5E5;
$gris-2: #D9D9D9;
$gris-3: #A1A1A1;
$gris-4: #727176;

$naranja-1: #F9AD01;
$naranja-2: #E18300;

$verde-1: #7abc43;


$radius-20: 20px;
$radius-50: 50px;







