.blog {
    .blog-entradas {
        .card {
            .enlace {
                    text-decoration: none;
            }
            .card-text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
                @include desdeLaptop {
                    font-size: 1vw;
                }
                @include desdeEscritorioBig {
                    font-size: .8vw;
                }
            }
        }
        &.derecha {
            .blog-entrada-singular {
                flex: 0 0 calc(50%);
                &:last-child {
                    flex: auto;
                }
            }
        }
    }
}